import React, { Component } from "react";


import ReactMarkdown from 'react-markdown'



class ListProduct extends Component {



    render() {


        return (
            <section className="padding-section-int-not">
                <div className="container">
                    {
                        this.props.product.map((item, index) => {
                            return (
                                <React.Fragment>

                                    <div key={index} className="row gestion-int-pro">
                                        {
                                            !item.izq_o_deq && <div className="col-sm-6 text-center wow zoomIn" >
                                                <p
                                                 data-sal="zoom-in"
                                                 
                                                 data-sal-duration="800"
                                                ><img src={item.img.url} className="img-fluid" alt="" /></p>
                                            </div>
                                        }
                                        <div className="col-sm-6">
                                            <h4>{item.titulo}</h4>
                                            <div className="mb-0-p"><ReactMarkdown>{item.descripcion}</ReactMarkdown></div>
                                        </div>
                                        {
                                            item.izq_o_deq && <div className="col-sm-6 text-center wow zoomIn" >
                                                <p
                                                 data-sal="zoom-in"
                                                 
                                                 data-sal-duration="800"
                                                ><img src={item.img.url} className="img-fluid" alt="" /></p>
                                            </div>
                                        }
                                    </div></React.Fragment>
                            )
                        })
                    }





                </div>
            </section>




        )
    }
}

export default ListProduct
