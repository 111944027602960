import React, { Component } from "react";


import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class Carrusel extends Component {



   render() {

      var settings = {
         dots: false,
         arrows: false,
         infinite: true,
         slidesToShow: 4,
         slidesToScroll: 1,
         speed: 100,
         autoplaySpeed: 5000,
         pauseOnHover: true,
         responsive: [

            {
               breakpoint: 1200,
               settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  initialSlide: 3
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }
         ],
      };
      return (

         <section className="padding-section">
            <div className="container">
               <div className="row">
                  <div className="col-md-12 titulo-center text-center">
                     <h2>{this.props.carrusel.titulo_gestion_calidad.titulo}</h2>               </div>
                  <div className="col-md-12 wow fadeInUp">
                     <div id="gestion" >
                        <Slider
                           {...settings}
                        >
                           {
                              (this.props.carrusel.gestion_de_calidad).concat(this.props.carrusel.gestion_de_calidad).map((item) => {
                                 return (
                                    <div style={{ marginRight: '20px' }} key={item.id} data-value={item.id} className="item text-center">
                                       <img src={item.imagen[0].url} alt={item.imagen[0].url} className="img-radius" />
                                       <h4 style={{ marginTop: '15px' }} >{item.titulo}
                                       </h4>
                                       <p></p><p style={{ lineHeight: 1.6 }} >{item.descripcion}</p>
                                       <p></p>

                                    </div>
                                 )
                              })
                           }

                        </Slider>
                     </div></div>
               </div>

               <div className="row" style={{ marginTop: '20px' }}>
                  <div className="col-md-12 titulo-center text-center">
                     <p><a className="btn btn-primary" href={this.props.carrusel.btn_gestion_calidad.enlace}><i className="fa fa-check"></i>  {this.props.carrusel.btn_gestion_calidad.titulo}</a></p>
                  </div>
               </div>

            </div>
         </section>



      )
   }
}

export default Carrusel
