import React, { Component } from "react";


import Layout from "../components/layout"
import Banner from "../components/others/banner"
import ListProduct from "../components/others/list_product"
import Carrusel from "../components/others/carrusel"


import { StaticQuery, graphql } from "gatsby"

const objectGraphql = graphql`{
    strapiProducto(locale: {eq: "es-PE"}) {
      Banner {
        fondo {
          url
        }
        url {
          enlace
          titulo
          id
        }
        titulo
        id
      }
      Descripcion {
        descripcion
        id
        titulo
        izq_o_deq
        img {
          id
          url
        }
      }
    }
    allStrapiHome(filter: {locale: {eq: "es-PE"}}) {
        edges {
          node {
            gestion_de_calidad {
                descripcion
                id
                titulo
                imagen {
                  url
                }
              }
            btn_gestion_calidad {
              enlace
              id
              titulo
            }
            titulo_gestion_calidad {
              id
              titulo
            }
          }
        }
      }
  }
  
`


class Product extends Component {

    render() {


        return (
            <StaticQuery
                query={objectGraphql}
                render={data => (
                    <React.Fragment>
                        <Layout nameTab='Productos y servicios' props={this.props.props}>
                            <Banner banner={data.strapiProducto.Banner} />
                            <ListProduct product={data.strapiProducto.Descripcion}/>
                            <Carrusel carrusel={data.allStrapiHome.edges[0].node}/>
                        </Layout>
                    </ React.Fragment>
                )}
            />
        )
    }
}

export default Product
