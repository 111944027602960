import React, { Component } from "react";





class Banner extends Component {

    links = (data) => {
        console.log(this.props.banner.url.length);
        if (this.props.banner.url.length -1 === data.index ) {
            return (
                <li key={data.index} className={`breadcrumb-item active`}>{data.item.titulo}</li>)
       
        } else {
            return (
                <li key={data.index}  className={`breadcrumb-item`}><a href={data.item.enlace}>{data.item.titulo}</a></li>)
       
        }
    }

    render() {


        return (

            <section className="titulo-int"
                style={{ backgroundImage: "url(" + this.props.banner.fondo[0].url + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 titulo-center-b text-center">
                            <h2>{this.props.banner.titulo}</h2>

                            <ol className="breadcrumb">
                                {
                                    this.props.banner.url.map((item, index) => {
                                        return (

                                            this.links({ item, index })

                                        )
                                    })
                                }
                            </ol>
                        </div>
                    </div>
                </div>
            </section>




        )
    }
}

export default Banner
