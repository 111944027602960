import * as React from "react"
import appInsights from '../../../../AppInsights'; 
import Product from "../../../route/products"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Product props={props} />
      </React.Fragment>
  )
}

export default IndexPage
